.left-side {
  position: relative;
  width: 4vw;
  max-width: 80px;
  height: 100vh;
  background: #29323e;
  //background: #8794CA;
  display: flex;
  flex-direction: column;

  &__logo {
    width: 50%;
    margin: 25px 25% 44px;
  }

  &__content {
    width: 100%;
    position: relative;
    flex: 1;
  }

  &__footer {
    width: 100%;
    position: relative;
  }
}
