.second-step {
  width: 100%;

  &__slider {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .second-step__slider-label {
      font-family: PingFangSC, PingFang SC, serif;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      letter-spacing: 1px;
      font-style: normal;
      display: inline-block;
      margin-top: 8px;
      margin-right: 85px;
      min-width: 120px;
    }

    & .ant-slider {
      width: 500px
    }
  }

  &__upload {
    display: flex;
    justify-content: center;
    padding: 50px 0;

    &.second-step__upload--upload_dragger {
      padding: 0;
    }

    & .second-step__upload-simulation {
      width: 100%;
      height: 100%;
    }


    .ant-upload-wrapper {
      width: 50%;
    }
  }

  &__simulation {
    padding: 0;
    display: flex;
    flex-direction: column;

    .second-step__build_type {
      display: flex;
      align-items: center;
      font-family: PingFangSC, PingFang SC, serif;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      letter-spacing: 1px;
      font-style: normal;
      margin-top: 16px;

      &_label {
        display: inline-block;
        min-width: 90px;
        margin-right: 10px;
      }
    }
  }

  &__uploaded {
    width: 100%;

    .chart-top-card-group {
      display: flex;
      justify-content: flex-start;
      margin: 20px;
    }
  }

  .second-step__upload-loading {
    height: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div:nth-child(2) {
      display: inline-block;
      margin-top: 10px;
      font-family: PingFangSC, PingFang SC, serif;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      text-align: center;
      font-style: normal;
    }
  }
}
