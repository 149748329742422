.menu-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding-top: 24px;
  &__image {
    width: 26%;
  }

  &__content {
    margin-top: 6px;
    letter-spacing: 2px;
  }

  &__title {
    font-family: PingFangSC, PingFang SC;
    font-size: 14px;
    color: #FFFFFF;
    font-style: normal;
    text-transform: uppercase;
  }
}
