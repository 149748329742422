#divToPrint {
  .chart-top-card-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .reporter-footer {
    margin-top: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .qrcode-container {
      margin-right: 24px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      img {
        width: 80px;
        height: 80px;
      }

      .qrcode-content {
        margin-top: 4px;
      }
    }

    .logo-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 10px;

      img {
        height: 40px;
      }

      .logo-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        span {
          line-height: 20px;
          margin-top: 8px;
          a {
            color: #2991ca;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
  }
}

.system-param-group {
  width: 100%;
  padding: 20px 0;

  .centered-content {
    padding-left: 60px!important;
  }

  .ant-statistic-content-suffix {
    font-size: 14px;
  }
}

div.br-8 {
  margin-bottom: 8px;
}
