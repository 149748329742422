.plan-project-container {
  height: 100%;

  .plan-project__main {
    display: flex;
    padding: 0 24px;
    max-width: 100vw;
    height: calc(100% - 100px);
    flex-wrap: wrap;
    overflow-y: auto;

    .plan-project__main_empty {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
}
