.divider-title {
  font-family: PTSerif, PTSerif, serif;
  font-weight: 400;
  font-size: 16px;
  color: #525A65;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: uppercase;

  &__maincontent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    margin-bottom: 8px;
  }

  &__refresh {
    margin-left: 10px;
  }

  &__divider {
    border-top: 1px solid rgb(82, 90, 101, .34);
  }
}
