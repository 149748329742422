.title-board {
  height: 100px;
  max-height: 100px;
  min-height: 100px;
  background-color: #fff;
  border-bottom: 2px solid #E3E4E5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 32px;

  &__subtitle {
    font-family: AppleSystemUIFont, serif;
    font-size: 13px;
    color: #A8ACB2;
    text-align: left;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &__divider {}
  &__title {
    //font-family: STSongti-SC, STSongti-SC, serif;
    font-weight: 400;
    font-size: 24px;
    color: #2991CA;
    text-align: left;
    font-style: normal;
    text-transform: uppercase;
  }
}
