.evaluate-container {
  &__item {
    &__title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &__content {
      // 非最后一个
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__btn {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
