.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 15px 0 6%;
  font-family: PingFangSC, PingFang SC, serif;
  font-size: 13px;
  color: #525A65;
  line-height: 18px;
  text-align: center;
  font-style: normal;
}
