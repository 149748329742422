.project-item-container {
  position: relative;
  width: 278px;
  min-width: 278px;
  height: 294px;
  min-height: 294px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #E9E9E9;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  margin-right: 24px;
  margin-top: 20px;

  .project-item-img {
    width: 100%;
    height: 164px;
    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  .project-item {
    padding: 24px 24px 12px;
    &__name {
      font-family: PingFangSC, PingFang SC, serif;
      font-weight: 500;
      font-size: 17px;
      color: rgba(0,0,0,0.85);
      line-height: 25px;
      text-align: left;
      font-style: normal;
    }
    &__desc {
      font-family: PingFangSC, PingFang SC, serif;
      font-size: 15px;
      color: rgba(0,0,0,0.45);
      line-height: 23px;
      height: 23px;
      text-align: left;
      font-style: normal;
    }
  }

  .project-item__btn__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    & > div {
      display: flex;
    }
    .project-item__update-time {
      font-family: HelveticaNeue, serif;
      font-size: 12px;
      color: rgba(0,0,0,0.45);
      line-height: 21px;
      text-align: left;
      font-style: normal;
    }
    .project-item__btn {
      margin-left: 8px;
    }
  }
}
