.op-step-container {
  width: 940px;
  min-width: 940px;
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;

  &__item-title {
    width: 174px;
    height: 18px;
    font-family: PingFangSC, PingFang SC, serif;
    font-size: 13px;
    color: #525A65;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: right;
    font-style: normal;
    text-transform: uppercase;
    position: absolute;
    top: 16px;
  }

  &__item {
    position: relative;
    min-width: 236px;

    // 第一个元素
    &:first-child {
      .op-step-container__item-title {
        left: -40px;
      }
    }
  }

  &__item_last {
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    background-color: #fff;
    height: 48px;

    &.active {
      background-color: #2098F1;
      color: #fff;
    }
  }
}
