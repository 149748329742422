.time-display {
  width: 100%;
  font-family: PingFangSC, PingFang SC, serif;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 18px;
  text-align: center;
  font-style: normal;
  text-transform: uppercase;
  margin-top: 20px;
}
