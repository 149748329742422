.chart-top-card {
  position: relative;
  width: 240px;
  height: 80px;
  display: flex;
  flex-direction: column;
    justify-content: center;
  padding: 5px 5px;
  font-size: 16px;
  color: #333;
  background: #e0e5ec;
  border: none;
  border-radius: 8px;
  //box-shadow: 8px 8px 15px #a3b1c6, /* 外阴影 */
  //-8px -8px 15px #ffffff; /* 内阴影 */
  cursor: pointer;
  margin-right: 18px;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }

  &__title {
    font-family: PingFangSC-Regular, serif;
    font-size: 14px;
    color: #69737D;
    letter-spacing: 1.3px;
    margin-bottom: 8px;
  }
  &__value {
    font-family: 'LCD', sans-serif;
    //color: rgba(15, 15, 20, 1.000);
    font-size: 24px;
    line-height: 30px;
    //color: #2B3037;
    letter-spacing: 1px;
    font-weight: 600;
    color: rgba(41, 145, 202, 1.000);
    span {
      // unit
      font-size: 14px;
      margin-left: 5px;
      font-weight: 500;
      color: rgba(15, 15, 20, 1.000);
    }
  }

  .bottom-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: rgba(25, 144, 255, .68);
  }
}
