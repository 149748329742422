.login-modal {
.count-down {
  color: #1890FF;
}
}

.login-modal_title {
  display: flex;
  flex-direction: column;

  &__logo {
    width: 93px;
    margin-bottom: 30px;
  }

  &__text {
    font-family: PingFangSC-Regular, serif;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 16px;
    color: #1890FF;
    line-height: 24px;
  }

  &__line {
    width: 10%;
    height: 2px;
    background-color: #1890FF;
    margin: 6px 0 20px 20px;
  }
}
