@import url('https://fonts.cdnfonts.com/css/lcd');
/*@font-face {*/
/*  font-family: lcd;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local('LCD'),url(../src/assets/font/LCD-N___.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcd;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local('LCD'),url(../src/assets/font/LCD-U___.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcd;*/
/*  font-style: normal;*/
/*  font-weight: 300;*/
/*  src: local('LCD'),url(../src/assets/font/LCD-L___.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcd;*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  src: local('LCD'),url(../src/assets/font/LCD-BOLD.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcd2;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local('LCD2'),url(../src/assets/font/LCD2N___.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcdmono2;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local('LCDMono2'),url(../src/assets/font/LCDM2N__.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcdmono;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local('LCDMono'),url(../src/assets/font/LCDMN___.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: digitalism;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local('Digitalism'),url(../src/assets/font/digitalism.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcd2;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local('LCD2'),url(../src/assets/font/LCD2U___.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcdmono2;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local('LCDMono2'),url(../src/assets/font/LCDM2U__.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcdmono;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: local('LCDMono'),url(../src/assets/font/LCDMU___.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcd2;*/
/*  font-style: normal;*/
/*  font-weight: 300;*/
/*  src: local('LCD2'),url(../src/assets/font/LCD2L___.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcdmono2;*/
/*  font-style: normal;*/
/*  font-weight: 300;*/
/*  src: local('LCDMono2'),url(../src/assets/font/LCDM2L__.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcdmono;*/
/*  font-style: normal;*/
/*  font-weight: 300;*/
/*  src: local('LCDMono'),url(../src/assets/font/LCDML___.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcd2;*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  src: local('LCD2'),url(../src/assets/font/LCD2B___.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcdmono2;*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  src: local('LCDMono2'),url(../src/assets/font/LCDM2B__.woff) format('woff')*/
/*}*/

/*@font-face {*/
/*  font-family: lcdmono;*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  src: local('LCDMono'),url(../src/assets/font/LCDMB___.woff) format('woff')*/
/*}*/


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
