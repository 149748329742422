.third-step {
  font-family: PingFangSC, PingFang SC, serif;
  font-size: 14px;
  color: rgba(0,0,0,0.85);
  line-height: 22px;
  font-style: normal;

  &__item {
    margin-left: 30%;
    margin-top: 30px;

    &__label {
      display: inline-block;
      min-width: 19%;
    }

    .label2 {
      margin-left: 10px;
      font-size: 13px;
    }
  }
}
