.main-layout {
    display: flex;
    height: 100vh;

    &__left-nav {
        height: 100vh;
    }
    &__right-content {
        flex: 1;
        height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;

        &__top-head{
            height: 50px;
            min-height: 50px;
            width: 100%;
            border-bottom: 2px solid #E3E4E5;
            display: flex;
            align-items: center;
        }

        &__outlet {
            position: relative;
            flex: 1;
            max-height: calc(100vh - 100px);
            min-height: calc(100vh - 100px);
            background-color: #F1F2F5;
        }

        &__foot-boot {
            position: relative;
            display: flex;
            align-items: center;
            height: 50px;
            min-height: 50px;
            border-top: 2px solid #E3E4E5;
        }
    }
}
