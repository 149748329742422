.top-head-content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  &-left {
    display: flex;
    align-items: center;
    font-weight: 700;
    letter-spacing: 0.1px;
    // 黑体
    font-family: 'Microsoft YaHei', serif;
    font-size: 20px;
  }
  &-right {
    display: flex;
    align-items: center;
  }
}
