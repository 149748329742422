.elec-price-item {
  padding: 0 14%;
  display: flex;

  &__price {
    min-width: 35%;
    font-family: PingFangSC, PingFang SC, serif;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    line-height: 22px;
    text-align: center;
    font-style: normal;

    & .label2 {
      font-size: 13px;
      margin-left: 6px;
    }
  }

  &__add-button {
    background: #FFFFFF;
    box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05), 0px 6px 16px 0px rgba(0,0,0,0.08), 0px 3px 6px -4px rgba(0,0,0,0.12);
    margin-left: 12px;
  }

  .time-proid {
    margin-left: 28px;
    display: flex;
    justify-content: flex-start;
    font-family: PingFangSC, PingFang SC, serif;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    line-height: 22px;
    text-align: center;
    font-style: normal;
    .label-container {
      min-width: 20%;
      margin-top: 5px;
    }
    .picker-container {
      display: flex;
      flex-direction: column;

      & .ant-picker {
        margin-bottom: 15px;
      }
    }

    .picker-group {
      display: flex;

    }

  }
}
