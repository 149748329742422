

.loading-item{
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.loading > .wrap {
  font-size: 15px;
  color: rgb(82, 90, 101);
  border-right: 0.08em solid rgb(82, 90, 101);
}

.loading-item > .text {
  font-size: 15px;
  color: rgb(82, 90, 101);
}

.count{
  font-size: 15px;
  color: rgb(82, 90, 101);
}
