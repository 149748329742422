.result-tag-container {
  width: 50%;
  display: flex;
  &__tag {}
  &__right {
    display: flex;
  }
  &__value {}
  &__unit {}
}
