.account-group {
  display: flex;
  align-items: center;

  &__login {
    font-family: PingFangSC, PingFang SC, serif;
    font-size: 13px;
    cursor: pointer;
    color: #525A65;
    text-align: center;
    font-style: normal;
    margin-left: 10px;
    margin-right: 24px;

    &:hover {
      color: #1890FF;
    }
  }

  &__name {
    font-family: PingFangSC, PingFang SC, serif;
    font-size: 13px;
    color: #525A65;
    text-align: center;
    font-style: normal;
    margin-left: 10px;
    margin-right: 24px;
  }
}
