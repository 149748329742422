.base-info-container {
  height: 100%;
  .base-info__main {
    display: flex;
    padding: 24px 24px;
    max-width: 100vw;
    height: calc(100% - 100px);
    flex-wrap: wrap;
    overflow-y: auto;
    .base-info__content {
      position: relative;
      width: 60%;
      height: 96%;
      padding: 20px;
      background: #FFFFFF;
      border-radius: 6px;
      .ant-tabs {
        height: 100%;
        .ant-tabs-content {
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }
}
