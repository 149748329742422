.plan-tool {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  &__main {
    width: 100%;
    flex: 1;
    //overflow-y: auto;
    overflow-x: hidden;
    //width: 940px;
    //min-width: 940px;
    margin: 32px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    overflow-y: auto;
  }
}
