.App {
  width: 100vw;
  height: 100vh;
}

/* scrollbar for chrome */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(87, 159, 241,.2);
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(87, 159, 241,.2);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
}
::-webkit-scrollbar-track:hover {
  background-color: rgba(87, 159, 241,.3);
  -webkit-box-shadow: inset 0 0 6px rgba(87, 159, 241,.4);
}
::-webkit-scrollbar-track:active {
  background-color: rgba(87, 159, 241,.5);
  -webkit-box-shadow: inset 0 0 6px rgba(25, 144, 255,.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(25, 144, 255, .3);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,.1);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(87, 159, 241, .6);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,.1);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(87, 159, 241, .8);
}
/* scrollbar for chrome end */


.main-content-width {
  width: 940px;
  min-width: 940px;
}
